import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timezone',
  templateUrl: './timezone.component.html',
  styleUrls: ['./timezone.component.scss']
})
export class TimezoneComponent implements OnInit {

  fechaHora;
  infoIncorrecta=false;
  veriInformacion=false;

  constructor(@Inject(MAT_DIALOG_DATA) public data:any,
    private router:Router,
    public dialogRef: MatDialogRef<TimezoneComponent>) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  showHideInfoCorrecta(){
    this.infoIncorrecta = !this.infoIncorrecta
    this.veriInformacion = false
  }

  showHideVeriInformacion(){
    this.veriInformacion = !this.veriInformacion
    this.infoIncorrecta = false
  }

  looksGood(){
    this.dialogRef.close(true);
  }

  verVideo(tipo){
    this.dialogRef.close(false);
    switch (tipo) {
      case "11":window.open("https://www.youtube.com/watch?v=nhkwZ17NXjY", '_blank');break;
      case "10":window.open("https://www.youtube.com/watch?v=knK_jtJVuc8", '_blank');break;
      case "MAC":window.open("https://www.youtube.com/watch?v=KHpf3wNgE84", '_blank');break;
      default:break;
    }
  }

  irAPerfil(){
    this.dialogRef.close(false);
    this.router.navigateByUrl("panel/settings/edit-profile")
  }

}
