import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  userSubs: Subscription = new Subscription();
  uiSubs: Subscription = new Subscription();

  hide = true;
  loading = false;

  email = "";
  password = "";

  recoverPassword = false;
  token="";

  constructor( private authService:AuthService, private store: Store<AppState>, private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.paramMap.get("id");
    this.userSubs = this.store.select('user').subscribe( (data) => {
      /*if(data != null){
        this.router.navigate(['panel']);
      }*/
    } )

    this.uiSubs = this.store.select('ui').subscribe( ui=>{
      this.loading = ui.isLoading;
    } )
  }

  ngOnDestroy(){
    this.userSubs.unsubscribe();
  }

  login() {
    let user = {"email": (this.email),"password": (this.password),"time_zone": 'America/Regina',"role_id": "3"};
    let encryptUser = {data: this.authService.encrypt(user,"public")};
    this.authService.login(user);
  }

  recoverPass(value){
    this.recoverPassword = value;
  }

  rePass() {
    let data = {
      email: this.email
    }
    this.loading = true;
    this.authService.rePassRequest(data).subscribe((d: any) => {
      let decData = JSON.parse(this.authService.decrypt(d.message, "public"));
      this.loading = false;
      Swal.fire({
        icon: 'success',
        title: 'Envio exitoso!',
        text: decData.data.items[0].message + this.email,
      });
      this.recoverPass(false);
    }, err => {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error, intente de nuevo.',
        text: err.error.error.errors[0].message,
      });
    })
  }

  newPass() {

    let data = {
      password: this.password,
      token: this.token
    }

    console.log(data)

    this.authService.rePassword(data).subscribe(d => {
      console.log(d);
      Swal.fire({
        icon: 'success',
        title: 'Listo',
        text: "Su contraseña ha sido cambiada",
      });
      this.router.navigate(['landing']);

    }, err => {
      let decData = JSON.parse(this.authService.decrypt(err.error.message, "public"));
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decData.error.errors[0].message,
      });
    });
  }

}
