import { Injectable } from '@angular/core';
import { Cache } from './storage.provider'; // Decorator to access local storage
import { ChatService } from './chat.service';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


let OneSignal;


@Injectable({
    providedIn: 'root'
})
export class OnesignalService {
    @Cache({ pool: 'OneSignal' }) oneSignalInit; // to check if OneSignal is already initialized. 
    @Cache({ pool: 'OneSignal' }) oneSignalId: any; // store OneSignalId in localStorage
    @Cache({ pool: 'Token' }) userSession: any; // User Session management token

    public tokenId = "";
    public UserId = "";

    constructor(private chatService: ChatService, private service: AuthService, private http: HttpClient  ) {
        console.log('OneSignal Service Init', this.oneSignalInit);
    }

    // Call this method to start the onesignal process.
    public init() {
        this.oneSignalInit ? console.log('Already Initialized') : this.addScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', (callback) => {
            console.log('OneSignal Script Loaded');
            this.initOneSignal();
        })
    }

    addScript(fileSrc, callback) {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = callback;
        script.src = fileSrc;
        head.appendChild(script);
    }

    initOneSignal() {
        OneSignal = window['OneSignal'] || [];
        console.log('Init OneSignal');
        OneSignal.push(['init', {
            appId: 'f6aac5c9-b5ca-49c0-bd51-1125b7cac158',
            autoRegister: true,
            allowLocalhostAsSecureOrigin: true,
            notifyButton: {
                enable: false,
            },
        }]);
        console.log('OneSignal Initialized');
        this.checkIfSubscribed();
    }

    subscribe() {
        OneSignal.push(() => {
            console.log('Register For Push');
            OneSignal.push(['registerForPushNotifications'])
            OneSignal.on('subscriptionChange', (isSubscribed) => {
                console.log('The user\'s subscription state is now:', isSubscribed);
                this.listenForNotification();
                OneSignal.getUserId().then((userId) => {
                    console.log('User ID is', userId);
                    this.oneSignalId = userId;
                    console.log(this.UserId)
                    this.updateLocalUserProfile();
                });
            });
        });
    }

    listenForNotification() {
        console.log('Initalize Listener');
        OneSignal.on('notificationDisplay', (event) => {
            console.log('OneSignal notification displayed:', event);
            this.listenForNotification();
        });
    }

    getUserID() {
        OneSignal.getUserId().then((userId) => {
            console.log('User ID is', userId);
            this.updateToken(userId);
            this.oneSignalId = userId;
            this.tokenId = userId;
        });
    }

    checkIfSubscribed() {
        OneSignal.push(() => {
            /* These examples are all valid */
            OneSignal.isPushNotificationsEnabled((isEnabled) => {
                if (isEnabled) {
                    console.log('Push notifications are enabled!');
                    this.getUserID();
                } else {
                    console.log('Push notifications are not enabled yet.');
                    this.subscribe();
                }
            }, (error) => {
                console.log('Push permission not granted');
            });
        });
    }

    updateLocalUserProfile() {
        // Store OneSignal ID in your server for sending push notificatios.
    }

    PostNotificationMessage(data) {
        var post = (data);
        var url = "https://onesignal.com/api/v1/notifications/";
        return this.http.post(url , post)
    }

    // Actualizar el token de one signal
    updateToken(token){
        let user = this.service.user;
        this.chatService.addTokenOS(user.id, {token} ).subscribe( d=>{
            console.log(d)
        } )
    }


}
