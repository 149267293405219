import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { OnesignalService } from '../services/onesignal.service';


@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit, OnDestroy {

  openMenu = true;
  titleNav = "";
  isLoading;
  titleNavSubscription: Subscription = new Subscription();
  uisubs: Subscription = new Subscription();

  constructor( private store: Store<AppState>, private service: AuthService, private osservice:OnesignalService) {
   }  

  ngOnInit() {
    this.titleNavSubscription = this.store.select('ui').subscribe(data =>{
      this.titleNav = data.title
    })
    this.uisubs = this.store.select('ui').subscribe( ui=>{
      this.isLoading = ui.isLoading;
    });

    this.service.conektaKey();

    this.osservice.init();
    // this.onSubscribe();
  }

  ngOnDestroy(){
    this.titleNavSubscription.unsubscribe();
  }

  logout(){
    this.service.logout();
  }

  onSubscribe() {
    var OneSignal = window['OneSignal'] || [];
    console.log("Init OneSignal");
    OneSignal.push(["init", {
      appId: "d4970b4d-2068-492c-9dc0-9ad9ae35762f",
      autoRegister: false,
      allowLocalhostAsSecureOrigin: true,
      notifyButton: {
        enable: false
      }
    }]);
    console.log('OneSignal Initialized');
    OneSignal.push(function () {
      console.log('Register For Push');
      OneSignal.push(["registerForPushNotifications"])
    });
    OneSignal.push(function () {
      // Occurs when the user's subscription changes to a new value.
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log("The user's subscription state is now:", isSubscribed);
        OneSignal.getUserId().then(function (userId) {
          console.log("User ID is", userId);
        });
      });
    });

  }

}
