import { Component, OnInit, AfterViewInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { PaymentBookingComponent } from './payment-booking/payment-booking.component';
import * as momentTz from 'moment-timezone'
import * as moment from 'moment';
import { MatCalendar } from '@angular/material';
import { ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';

@Component({
  selector: 'app-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.scss']
})
export class NewBookingComponent implements OnInit {

  timeLabel = "Seleccione una hora";
  time_zone = "";
  Today;
  planId = "no_plan";

  doctorId = "";
  doctorCatId = "";
  minDate = new Date();
  doctorTimeSlots = [];
  doctorDate = "";
  doctorTime = "";
  doctorFee = 0;
  cupon = "";
  cuponText = "";
  cuponValid = false;
  totalFee = 0;
  promo = 0;
  dateSelected = false;
  descuento = 0;
  creditValid = false;
  extra_nombre="";
  extra_apellido="";
  totalDates = 0;
  reviewsList = [{ score:0, message:"" }];
  averageReviews = 0;

  doctor = {
    account_status: "blocked",
    birth_at: 750492000000,
    city: "",
    country: "MX",
    country_code: "+52",
    country_code_flag: "",
    created_at: 1588688193873,
    deleted: false,
    email: "crowcl52@mind.mx",
    email_verified_at: 0,
    first_name: "Martin Eduardo",
    gender: "Male",
    id: 5,
    last_name: "Rubio",
    phone: "3324349525",
    photo_media_id: { url: "http://api.mindhelp.dev/a37d8de0-c122-454b-82e4-df8a480ba9af-1588688385455.png" },
    resume_media_id: { url: "http://api.mindhelp.dev/1656ae56-3d4f-4c13-af6b-63af26501f3d-1588688384869.pdf" },
    roles: [],
    services: [{ name: "", cost: 0 }],
    state: "",
    street_address: "",
    time_slots: [],
    time_zone: "America/Mexico_City",
    updated_at: 1589221054645,
    user_description: "todo bien",
    user_description_short: "",
    zip: ""
  }

  showExtras=false;
  calendarDays = [];

  // calendar
  selectedDate = moment();

  @ViewChild('calendar', { static: true })
  calendar: MatCalendar<moment.Moment>;
  isDatePicker = false;
  currentMonth = moment().format('MM/DD/YYYY');
  onlyMonth = moment().format('MMMM');

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private service: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private renderer: Renderer2) { }

  ngOnInit() {
    moment.locale('es');
    this.checkPromoCode();
    this.getUsesUser();
    this.getCurrentSuscription();
    // agarrar el time zone
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
    this.time_zone = resolvedOptions.timeZone;
    let date = new Date().getTime();

    this.doctorId = this.route.snapshot.paramMap.get("id");
    this.doctorCatId = this.route.snapshot.paramMap.get("cat_id");
    this.getDoctorDetail(this.doctorId);
    this.getReviews();
    this.getTimeSlots();
  }

  ngAfterViewInit(): void {
    this.highlightDays(this.calendarDays);
  }

  ngDoCheck(): void {
    let c: any = this.calendar;
    let currentMonth = moment(c._clampedActiveDate).format('MM/DD/YYYY');
    if (this.currentMonth != currentMonth) {
      this.onlyMonth = moment(c._clampedActiveDate).format('MMMM');
      console.log(currentMonth, this.currentMonth);
      this.currentMonth = currentMonth;
      this.getTimeSlots();
      this.highlightDays(this.calendarDays);
      this.isDatePicker = false;
      this.dateSelected = false;
      this.doctorFee = 0;
      this.getDoctorDetail(this.doctorId);
    }
  }

  private highlightDays(days: string[]) {
    const dayElements = document.querySelectorAll(
      'mat-calendar .mat-calendar-table .mat-calendar-body-cell'
    );
    // console.log("Calendario")
    // console.log(days)
    Array.from(dayElements).forEach((element) => {
      let day = moment(element.getAttribute('aria-label')).format('MM/DD/YYYY');
      const matchingDay = days.find((d) => d === day) !== undefined;

      if (matchingDay) {
        this.renderer.addClass(element, 'available');
        this.renderer.setAttribute(element, 'title', 'Event 1');
      } else {
        this.renderer.removeClass(element, 'available');
        this.renderer.removeAttribute(element, 'title');
      }
    });
  }

  checkPromoCode(){
    let user = this.service.user;
    console.log(user)
    if( user.free_promo_code ){
      console.log(user.free_promo_code);
      this.cupon = user.free_promo_code.code;
    }
  }

  getTimeSlots() {
    this.store.dispatch(new ActivateLoadingAction());

    let day = moment.utc(this.currentMonth).format('x');
    let id = this.doctorId;
    console.log("Time slots")

    this.service.getHours(id, day, 'month').subscribe((data: any) => {
      let decUser = JSON.parse(this.service.decrypt(data.message, "public"));
      console.log(decUser.data.items)
      let offset = moment().utcOffset();
      let newDays = decUser.data.items.map(elem => {
        let dayD = elem.day;
        let dayOff = elem.day - (Math.abs(offset) * 60000);
        let dayM = moment(elem.day);
        let dayUtc = moment.utc((elem.day - (Math.abs(offset) * 60000)));
        let dayMOff = moment((elem.day - (Math.abs(offset) * 60000)));
        return moment(elem.day).format("MM/DD/YYYY");//return moment((elem.day - (Math.abs(offset) * 60000))).format("MM/DD/YYYY");//return moment.utc((elem.day - (offset * (60000)))).format("MM/DD/YYYY"); //moment(elem.day).format('MM/DD/YYYY');
      })
      console.log(newDays);
      this.calendarDays = newDays;
      this.highlightDays(this.calendarDays);
      this.store.dispatch(new DeactivateLoadingAction());
    }, err => {
      this.store.dispatch(new DeactivateLoadingAction());
    })
  }

  getDoctorDetail(doc_id) {
    this.service.getDoctorDetail(doc_id, this.doctorCatId).subscribe((data: any) => {
      let decData = JSON.parse(this.service.decrypt(data.message,"public"));
      if(decData.data.items == 0){
        Swal.fire({
          icon: 'error',
          title: 'No Disponible',
          text: "Lo sentimos, este terapeuta no se encuentra disponible por el momento",
        });
        this.router.navigate(['/panel/home']);
        return;
      }
      this.doctor = decData.data.items[0];
      this.totalDates = decData.data.items[0].time_slots.filter(obj =>{
        if(obj.slot_type ==='availability') return true;
        return false;
      }).length; //decData.data.items[0].time_slots.length;
    }, err => {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'No Disponible',
        text: "Lo sentimos, este terapeuta no se encuentra disponible por el momento",
      });
      this.router.navigate(['/panel/home']);
      // this.presentAlert(err.error.msg);
    })
  }

  dateChanged() {
    console.log("date change", this.selectedDate)
    this.isDatePicker = true;
    this.dateSelected = false;
    this.getDate(this.selectedDate);
  }

  getDate(date) {

    this.doctorDate = moment(date).format('MM/DD/YYYY');
    console.log(moment(date).valueOf())
    this.service.getDoctorTimeList(this.doctorId, moment(date).valueOf()).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "public"));

      let slots = this.formatSlots(decData.data.items);

      let GMT = moment().format('ZZ');

      let newSlots = []
      slots.forEach( slot =>{
        console.log(moment(slot.start).format('LLL'));
        var dateIni = moment(slot.start);
        var dateFin = moment().add(30,'minutes');
        ////var dateIni = GMT.charAt(0) == '+' ? moment(slot.start).add(Number(GMT.slice(1,5))/100,"hours") : moment(slot.start).subtract(Number(GMT.slice(1,5))/100,"hours");
        ////var dateFin = moment().add(30,'minutes');//moment().utc();//
        if( moment(slot.start).format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY'))
        ////if( dateIni.format('DD-MM-YYYY') == moment(date).format('DD-MM-YYYY'))
          if(dateIni.isAfter(dateFin))
            newSlots.push( { start: moment(slot.start).format('HH:mm'), start_hour:moment(slot.start).format('h:mm a'), slot_type:slot.slot_type } );
            //newSlots.push( { start: moment(slot.start).format('HH:mm'), slot_type:slot.slot_type } );
            ////newSlots.push( { start: dateIni.format('HH:mm'), slot_type:slot.slot_type } );
      });

      // console.log(newSlots);
      ////this.doctorTimeSlots = newSlots;

      let sortedProducts = newSlots.sort(
        (p1, p2) => (Number(p1.start.split(":")[0]) < Number(p2.start.split(":")[0])) ? -1 : (Number(p1.start.split(":")[0]) > Number(p2.start.split(":")[0])) ? 1 : 0);

      this.doctorTimeSlots = sortedProducts;

      this.timeLabel = "Seleccione una hora";
      if (this.doctorTimeSlots.length == 0) {
        this.timeLabel = "No hay horas disponibles";
      } else if (this.Today != this.doctorDate) {

      }

    }, err => {
      console.log(err)
    });

  }

  compareNumbers(a, b) {
    return a - b;
  }

  monthChanged($event) {
    console.log($event);
  }

  formatSlots(slots){
    let newTimesS = [];
    slots.forEach(element => {
      // get the day of the mont
      let date = moment.utc(element.day ).format('MMM/DD/YYYY');

      // get the times in the day of the mont
      let newTimes = element.availability.map(elem => {

        let start = moment(elem.start_at_miliseconds_utc).format();
        let plushour = moment(elem.start_at_miliseconds_utc).add(1,'hour').format();

        let end =  moment(plushour).format()

        return {
          start: new Date(start),
          minutes: elem.minutes,
          slot_type: elem.slot_type
        }
      })

      newTimesS = newTimesS.concat(newTimes)

    });
    return newTimesS
  }

  getTime(time) {
    console.log(time);
    this.doctorTime = time;
    this.getFee();
  }

  getFee() {
    this.doctorFee = this.doctor.services[0].cost;
    this.totalFee = this.doctorFee;
    this.dateSelected = true;
    if( this.planId != "no_plan") {this.applyCupon();}
  }

  applyCupon() {
    this.cuponText = "";
    this.totalFee = this.doctorFee;

    let data = {
      code: this.cupon,
    }

    this.service.applyCupon(data).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,"private"));

      console.log(decData.data.items[0])
      let data = decData.data.items[0]
      this.cuponValid = data.isValid;
      if(!this.cuponValid){
        this.resetCupon();
        this.cuponText = "El cupon no se puede aplicar.";
        this.totalFee = this.doctorFee;
        return;
      }
      this.cuponText = "Cupon aplicado";

      if (data.flat_amount === 0) {
        this.promo = (this.totalFee * data.discount_percentage)
        this.descuento = (this.totalFee * data.discount_percentage)
      } else {
        this.promo = data.flat_amount;
        this.descuento = data.flat_amount;
      }

      console.log(this.promo)

      this.totalFee -= this.promo;

      console.log(this.totalFee)

    }, err => {
      console.log(err)
      this.resetCupon();
      this.cuponText = "El cupón no es valido";
      this.cuponValid = false;
      this.totalFee = this.doctorFee;
    })

  }

  applyCredit(){
    this.service.getUsesUser().subscribe((res: any) =>{
      let encData = JSON.parse(this.service.decrypt(res.message, 'private'));
      if(encData.data.items.length > 0){
        this.service.user.max_uses = encData.data.items[0].max_uses;
        this.service.user.uses = encData.data.items[0].uses;
        this.service.user.uses--;
      }
    },()=>{});
    this.creditValid = true;
    this.totalFee = 0;
  }

  disapplyCredit(){
    this.service.getUsesUser().subscribe((res: any) =>{
      let encData = JSON.parse(this.service.decrypt(res.message, 'private'));
      if(encData.data.items.length > 0){
        this.service.user.max_uses = encData.data.items[0].max_uses;
        this.service.user.uses = encData.data.items[0].uses;
      }
    },()=>{});
    this.creditValid = false;
    this.totalFee = this.doctorFee;
  }

  saveBooking() {
    console.log(this.totalFee);
    if(this.totalFee <= 0)
      this.suscriptionBooking();
    else
      this.openDialog();

    // return;
    ////if( this.planId != "no_plan" && this.cuponValid ){
    ////this.suscriptionBooking();
    ////}else{
      ////this.openDialog();
    ////}
  }

  openDialog() {
    const dialogRef = this.dialog.open(PaymentBookingComponent, {
      width: '800px',
      height: '600px',
      data: { isSold: false, index: null }
    });

    dialogRef.afterClosed().subscribe(result => {

      console.log(result);

      if (result != undefined) {

        let time = this.doctorTime+":00";
        let date = this.doctorDate + " " + time;
        console.log(date)

        let start_at = momentTz(date).tz('UTC').format('x');
        console.log(start_at);

        let dateplus = moment(date).add(1,'hour').format('LLL');
        let end_at = momentTz(dateplus).tz('UTC').format('x');

        let method = this.cuponValid ? this.service.PAYMETHODS.tarjetacupon:this.service.PAYMETHODS.tarjeta;

        let data = {
          start_at,
          end_at,
          doctor_user_id: Number(this.doctorId),
          patient_user_id: this.service.user.id,
          base_price: this.doctorFee,
          final_price: this.totalFee,
          review: 5,
          promo_code: this.cupon,
          index: result.index,
          paymentmethod_id: method
        }

        console.log(data)
        // return;

        this.service.saveBooking(data).subscribe((d: any) => {
          console.log(d)
          Swal.fire({
            icon: 'success',
            title: 'Felicidades',
            text: "Tu Cita a sido aprobada con éxito",
          });
          this.router.navigate(['/panel/booking']);
        }, err => {
          let decUser = JSON.parse(this.service.decrypt(err.error.message, "private"));
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error',
            text: decUser.error.errors[0]//err.error.error.errors[0].message,
          });
        });
      }



    });
  }

  suscriptionBooking(){
    let time = this.doctorTime+":00";
    let date = this.doctorDate + " " + time;
    console.log(date)

    let start_at = momentTz(date).tz('UTC').format('x');
    console.log(start_at);

    ////let dateplus = moment(date).add(1,'hour').format('LLL');
    ////let end_at = momentTz(dateplus).tz('UTC').format('x');
    let end_at = momentTz(date).add(1,'hour').tz('UTC').format('x');

    let method = this.creditValid ? this.service.PAYMETHODS.creditos:this.service.PAYMETHODS.cupon;

    var data = {
      start_at,
      end_at,
      doctor_user_id: Number(this.doctorId),
      patient_user_id: this.service.user.id,
      base_price: this.doctorFee,
      final_price: this.totalFee,
      review: 5,
      promo_code: this.cupon,
      index: 0,
      paymentmethod_id: method,
      extra_firstname: this.extra_nombre,
      extra_lastname: this.extra_apellido
    }

    if(!this.extra_nombre) delete data.extra_firstname;
    if(!this.extra_apellido) delete data.extra_lastname;

    console.log(data)
    this.service.saveBooking(data).subscribe((d: any) => {
      console.log(d)
      Swal.fire({
        icon: 'success',
        title: 'Felicidades',
        text: "Tu Cita a sido aprobada con éxito",
      });
      this.router.navigate(['/panel/booking']);
    }, err => {
      console.log(err)
      let decUser = JSON.parse(this.service.decrypt(err.error.message, "private"));
      console.log(decUser)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        text: decUser.error.errors[0],
      });
    });
  }

  getReviews(){
    this.service.getReviews(this.doctorId).subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message));
      this.reviewsList = decData.data.items;
      this.reviewsList.forEach(rev => {
        this.averageReviews += rev.score;
      });
      if(this.reviewsList.length > 0)
        this.averageReviews = Math.round(this.averageReviews/this.reviewsList.length);
    })
  }

  getCurrentSuscription(){
    this.service.getCurrentSuscription().subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      console.log(decData.data.items[0]);
      let data = decData.data.items[0];
      this.planId = data.plan.conekta_id;
      this.cupon = data.promo_code.code;
    },err =>{
      let decData = JSON.parse(this.service.decrypt(err.error.message, ""));
      console.log(decData.error.errors[0])
      this.planId = "no_plan";
    });
  }

  getUsesUser(){
    this.service.getUsesUser().subscribe((res: any) =>{
      let encData = JSON.parse(this.service.decrypt(res.message, 'private'));
      if(encData.data.items.length > 0){
        this.service.user.max_uses = encData.data.items[0].max_uses;
        this.service.user.uses = encData.data.items[0].uses;
      }
    },()=>{});
  }

  toogleExtras(){
    this.showExtras = !this.showExtras;
  }

  resetCupon(){
    setTimeout(function(){
      this.cuponText = "";
      this.cupon = "";
    },3000);
  }

  setDefaultPic(event){
    event.target.src = './assets/icono.png';
  }

}
