import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-suscription-modal',
  templateUrl: './suscription-modal.component.html',
  styleUrls: ['./suscription-modal.component.scss']
})
export class SuscriptionModalComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<SuscriptionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private service: AuthService, ) { }

  ngOnInit() {
    console.log(this.data)
  }

}
