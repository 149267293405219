import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Subscription } from 'rxjs';
import { CleartChatHistory } from '../redux/chat-history.actions';
import { ChangeTitleNav, ActivateLoadingAction } from '../redux/ui.actions';
import { RequestPushModel } from '../models/onesignal.models';
import { OnesignalService } from '../services/onesignal.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  chatList = [];
  chatHistory = [];
  chatInput = "";
  curentChatId = 0;
  currentChat: any = null;
  chatSubscribe: Subscription = new Subscription();
  userID =  0;
  chatInterval;

  constructor( 
    private service: AuthService, 
    private store: Store<AppState>,
    private osService: OnesignalService) { 
    }

  ngOnInit() {

    this.userID = this.service.user.id;
    
    this.chatSubscribe = this.store.select('chatHistory').subscribe( c =>{
      this.chatHistory = c.data.reverse();
      console.log(this.chatHistory);
    } );
    
    this.store.dispatch( new ChangeTitleNav("Chats"));
    
    this.getChats();

  }

  ngOnDestroy(){
    this.chatSubscribe.unsubscribe();
    this.store.dispatch( new CleartChatHistory() );
    this.store.dispatch( new ChangeTitleNav(""));
    clearInterval(this.chatInterval);
  }

  putImg() {
    console.log("enter")
    this.chatList.forEach((elem, index) => {
      let img = elem.patient_user_id.photo_media_id;
      if (typeof img === 'number') {
        this.service.getImgId(img).subscribe((d: any) => {
          this.chatList[index].doctor_user_id.photo_media_id = d.data.items[0].url
        });
      }
    });
}

  getChats(){
    this.service.getChats(this.service.user.id).subscribe((d:any)=>{
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      let data = decData.data.items;
      this.chatList = data;
      console.log(this.chatList)
      this.putImg();
    },err =>{
      console.log(err)
    })
  }

  getChatHistory(c){
    console.log(c)
    clearInterval(this.chatInterval);
    this.curentChatId = c.id
    this.currentChat = c;

    this.service.getChatHistory(c.patient_user_id.id, c.id);


    this.chatInterval = setInterval( ()=>{
      this.service.getChatHistory(c.patient_user_id.id, c.id);
    }, 7000 );
  }

  onSubmit(){    
    let data = {
      message: this.chatInput
    }

    this.service.sendMessage(this.service.user.id,this.curentChatId,data).subscribe((d: any) => {
      this.getChatHistory(this.currentChat);
      console.log(d)
    }, err => {
      console.log(err)
    });

    this.chatInput = "";
  }

  getMessage() {}


}
