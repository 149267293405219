import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { LoginComponent } from '../auth/login/login.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  categorias = [];

  constructor( private store: Store<AppState>,public dialog: MatDialog ) { }

  ngOnInit() {
    this.store.select('categories').subscribe(d =>{
      this.categorias = d.data;
      console.log(this.categorias)

    })
  }

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(LoginComponent, {
  //     width: '457px',
  //     height: '631px'
  //   });
  // }
}
