import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConektaService } from 'src/app/services/conekta.service';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AddCardComponent } from '../add-card/add-card.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
declare var Conekta:any;


@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss']
})
export class PagosComponent implements OnInit, OnDestroy {

  userSub: Subscription = new Subscription();
  userId = 0;

  cardToken = "";

  savedCards = [];
  // Production
  publicKey = "key_aX1B6yrp1V1cmkkr7rY47ng";

  constructor(
    private conektaService: ConektaService,
    public dialog: MatDialog,
    private store: Store<AppState>,
    private service: AuthService,
    private route: Router ) { }

  ngOnInit() {

    this.publicKey = this.service.conektaApiKey;
    console.log(this.publicKey)

    this.userSub = this.store.select('user').subscribe( d=>{
      this.userId = d.data.id;
      this.getCards();
    });

    Conekta.setPublicKey(this.publicKey);
    Conekta.setLanguage("es");

  }

  ngOnDestroy(){
    this.userSub.unsubscribe();
  }

  dialogRef = null;
  openDialog(): void {
    if(this.dialogRef == null){
      this.dialogRef = this.dialog.open(AddCardComponent, {
        width: '1200px',
        height: '800px',
      });
    }

    this.dialogRef.afterClosed().subscribe(result => {
      this.getCards();
      this.dialogRef = null;
    });

  }

  deleteCard(id){
    this.conektaService.deleteCard(this.userId,id).subscribe( d=>{
      Swal.fire({
        icon: 'success',
        title: 'Tarjeta Borrada',
        text: "Su tarjeta a sido borrada",
      });
    } )
  }

  getCards(){
    this.conektaService.getCards(this.userId).subscribe( (d:any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,"private"));

      console.log(decData.data.items);
      this.savedCards = decData.data.items;
      if(this.service.user){
        if(this.service.user.account_status == "plan_unpaid"){
          if(this.savedCards.length <= 0){
            this.openDialog();
          }else{
            this.route.navigate(['panel/settings/subscripciones']);
          }
        }
      }
    },err =>{
      console.log(err)
      let decData = JSON.parse(this.service.decrypt(err.error.message,"private"));
      console.log(decData)
    })
  }


}
