import { Component, OnInit, ChangeDetectorRef, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import * as OT from '@opentok/client';
import { OpentokService } from 'src/app/services/opentok.service';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Subscription } from 'rxjs';
import { VideoSettings } from 'src/app/redux/ui.reducer';
import { SetVideoSettings } from 'src/app/redux/ui.actions';
import { ActivatedRoute } from '@angular/router';
//import { ReviewComponent } from '../bookin/review/review.component';
import {CloseComponent } from './close/close.component';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import config from '../services/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss']
})
export class InquiryComponent implements OnInit, OnDestroy {
  title = 'Angular Basic Video Chat';
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  uiSUbscription: Subscription = new Subscription();
  video:VideoSettings = {
    video: true,
    audio: true
  }
  dateId = "";
  doctorId= "";
  userId="";
  validSession = false;
  constructor(
    private ref: ChangeDetectorRef,
    private opentokService: OpentokService,
    private store: Store<AppState>,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) {
      this.changeDetectorRef = ref;
      this.dateId = (this.route.snapshot.paramMap.get("id"));
      this.doctorId = this.route.snapshot.paramMap.get("doctor_id");
  }

  ngOnInit(){
    this.goSession();
  }

  startSession() {
    this.opentokService.initSession().then((session: OT.Session) => {
      this.session = session;
      this.session.on('streamCreated', (event) => {
        this.streams.push(event.stream);
        if(!this.changeDetectorRef['destroyed'])
          this.changeDetectorRef.detectChanges();
      });
      this.session.on('streamDestroyed', (event) => {
        const idx = this.streams.indexOf(event.stream);
        if (idx > -1) {
          this.streams.splice(idx, 1);
          if(!this.changeDetectorRef['destroyed'])
            this.changeDetectorRef.detectChanges();
        }
      });
    })
    .then(() => this.opentokService.connect())
    .catch((err) => {
      console.error(err);
      alert("Imposible conectarese. Asegurate que tienes actualizado el arhivo config.ts con tus detalles de OpenTok");
      //'Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.'
    });

    this.uiSUbscription = this.store.select('ui').subscribe( d=>{
      console.log(d.video)
      this.video = d.video;
    } )
  }

  goSession() {

    this.authService.conectInquiry(this.doctorId, this.dateId).subscribe((d: any) => {
      let decData = JSON.parse(this.authService.decrypt(d.message, 'public'));
      console.log(decData.data.items[0])
      if(decData.data.items.length > 0){
        if(decData.data.items[0].token){//Válida que la sesión tenga token para llevarse acabo
          this.userId = decData.data.items[0].patient_user_id;
          config.SESSION_ID = decData.data.items[0].conference_session_token;
          config.TOKEN = decData.data.items[0].token;
          console.log(config)
          this.startSession();
          this.validSession = true;
        }else{
          this.router.navigate(['/landing']);
        }
      }else{
        this.router.navigate(['/landing']);
      }
    })
  }

  ngOnDestroy(){
    if(this.validSession)
      this.opentokService.disconnect();
    if(this.uiSUbscription)
      this.uiSUbscription.unsubscribe();
    this.streams = [];
  }

  videoSettings(type){
    if(type == 'video'){
      this.video.video = !this.video.video;
    }else{
      this.video.audio = !this.video.audio;
    }
    this.store.dispatch( new SetVideoSettings( {...this.video} ) );
  }

  endCall(){
    this.dialog.open(CloseComponent, {
      width:'500px',
      height: '500px',
      data:{ id:this.dateId, userId:this.userId }
    });
  }

}
